<script lang="ts">
  import classNames from 'classnames';
  import { createEventDispatcher } from 'svelte';
  // Components
  import { Icon } from '$lib/components/atoms/Icon';

  export let label = '';
  export let name = '';
  export let search = false;
  export let multiple = false;
  export let options: Array<{ label: string; value: string }> = [];
  export let value: string | string[] | null = null;
  export let error: string | null | undefined = undefined;
  export let disabled = false;
  export let deselectable: boolean = false;
  export let minLength: number = 0;
  export let maxLength: number = 999999;
  export let nullOptionValue: string | null = null;

  let customSelectEl: HTMLElement;
  let menuEl: HTMLElement;
  let open = false;
  let filter = '';
  let id = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);

  $: hasValue = Array.isArray(value) ? value.length : !!value;
  $: selectedValues = Array.isArray(value) ? value : [value];
  $: textValue = selectedValues
    .map((val) => options.find((opt) => opt.value === val)?.label)
    .join(', ');
  $: filteredOptions = options.filter((opt) =>
    (opt.label + opt.value).toLowerCase().includes(filter.toLowerCase())
  );

  const dispatch = createEventDispatcher();

  const toggleMenu = (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    // cancel if click was inside menu
    if (menuEl.contains(target)) return;

    // fix event overlap
    setTimeout(() => {
      open = !open;
    }, 10);
  };

  const selectOption = (option: any) => {
    toggleOptionSelected(option);
    sendOnChangeEvent();

    if (!multiple) {
      open = false;
    }
  };

  const toggleOptionSelected = (option: any) => {
    if (multiple) {
      if (!Array.isArray(value)) {
        value = [];
      }

      const index = value.findIndex((val) => val === option.value);

      if (index >= 0 && value.length > minLength) {
        value.splice(index, 1);
      } else if (value.length < maxLength) {
        value.push(option.value);
      } else if (nullOptionValue === option.value) {
        value.push(option.value);
      }

      value = [...value];
    } else {
      if (deselectable === true && value === option.value) {
        value = null;
      } else {
        value = option.value;
      }
    }
  };

  const sendOnChangeEvent = () => {
    dispatch('change', {
      value,
    });
  };

  const onClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    // cancel if click was inside the entire component
    if (customSelectEl.contains(target)) return;

    open = false;
  };
</script>

<svelte:window on:click={onClickOutside} />

<div
  class={classNames('custom-select', {
    disabled
  }, $$props.class)}
  role="button"
  class:open
  class:has-value={hasValue}
  on:click={toggleMenu}
  bind:this={customSelectEl}
>
  {#if multiple && value}
    {#each value as val}
      <input
        id="custom-select-{id}"
        type="hidden"
        name={name + '[]'}
        value={val}
      />
    {/each}
  {:else}
    <input id="custom-select-{id}" type="hidden" {name} {value} />
  {/if}

  <div class="custom-select-field">
    <label class="custom-select-label" for="custom-select-{id}">{label}</label>
    {#if hasValue}
      <span class="custom-select-value">{textValue}</span>
    {/if}
  </div>

  <div class="custom-select-menu" bind:this={menuEl}>
    {#if search}
      <input
        class="custom-select-search-field"
        type="text"
        placeholder="Filtra le opzioni..."
        bind:value={filter}
      />
    {/if}

    {#each filteredOptions as option}
      <div
        class="custom-select-option"
        class:selected={selectedValues.find((val) => val === option.value) !=
          null}
        role="button"
        on:click={() => selectOption(option)}
      >
        <span class="option-checkbox">
          {#if selectedValues.find((val) => val === option.value) != null}
            <Icon icon="check" />
          {/if}
        </span>
        <span class="option-label">{option.label}</span>
      </div>
    {/each}
  </div>
</div>

{#if error}
  <span class="error">{error}</span>
{/if}

<style type="text/scss" lang="scss" scoped>
  .custom-select {
    @apply relative;
    @apply flex flex-col;
    @apply mb-3; /** same as TextField for errors */

    &.open {
      @apply bg-white shadow-card;
    }
  }

  .custom-select-field {
    @apply mb-0;
    @apply border-0 border-b border-gray-600 focus:border-brand;
    @apply h-11;
    @apply px-4 py-2;

    &::after {
      content: '';
      @apply block w-4 h-4;
      @apply absolute right-2;
      @apply top-1/2 transform -translate-y-1/2;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' \20
      fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 \20
      1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
    }

    .custom-select.open & {
      @apply border-brand;
    }
  }

  .custom-select-label {
    @apply absolute top-0 inset-x-0;
    @apply h-full;
    @apply overflow-hidden;
    @apply truncate;
    @apply px-4 py-2;
    @apply border border-transparent;
    @apply opacity-50;
    @apply transform origin-left;
    @apply transition-transform duration-300;
    @apply pointer-events-none;

    .custom-select.has-value & {
      @apply scale-80 -translate-y-3 translate-x-px;
    }
  }

  .custom-select-value {
    @apply truncate;
    @apply absolute left-4 right-8 bottom-0.5;
  }

  .custom-select-menu {
    @apply hidden;

    .custom-select.open & {
      @apply flex flex-col;
      @apply absolute top-11 inset-x-0;
      @apply px-4 pt-6 pb-4 -mt-1;
      @apply max-h-60 overflow-y-auto;
      @apply rounded-b;
      @apply bg-white shadow-card-notop;
      @apply z-10;
    }
  }

  .custom-select-option {
    @apply flex items-start;

    &:hover {
      .option-checkbox {
        @apply border-gray-900;
      }
      .option-label {
        @apply text-gray-900;
      }
    }

    .option-checkbox {
      @apply flex items-center justify-center;
      @apply flex-shrink-0;
      @apply w-5 h-5 rounded-sm;
      @apply border border-gray-600;
      @apply text-gray-900;
      @apply p-0.5;
      @apply mr-4;
    }

    .option-label {
      @apply text-sm py-1 -mt-1;
      @apply select-none;
    }

    & + & {
      @apply mt-3;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .custom-select-search-field {
    @apply rounded-none;
    @apply border-0 border-b border-gray-300;
    @apply outline-none shadow-none;
    @apply focus:border-brand;
    @apply text-sm;
    @apply px-0 py-1;
    @apply mb-4 -mt-2;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .error {
    @apply text-xs m-0;
    @apply text-error;
  }
</style>
